<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">分销商设置</span>
		</div>
		<div class="bg-white p-2 m-2">
			<el-form ref="form" :model="form" label-width="160px" style="max-width: 750px;">
				<el-card shadow="never" class="mt-24">
					<div slot="header">
						<span>基础设置</span>
					</div>
					<el-row>
						<el-form-item label="是否开启分销功能">
							<el-radio v-model="form.is_open" value="0" label="0">
								关闭
							</el-radio>
							<el-radio v-model="form.is_open" value="1" label="1">
								开启
							</el-radio>
						</el-form-item>
						<el-form-item label="分销层级">
							<el-radio v-model="form.level" value="1" label="1">
								一级
							</el-radio>
							<el-radio v-model="form.level" value="2" label="2">
								二级
							</el-radio>
							<el-radio v-model="form.level" value="3" label="3">
								三级
							</el-radio>
						</el-form-item>
						<el-form-item label="佣金结算天数">
							<el-input-number :min="0" v-model="form.settle_days"></el-input-number>
						</el-form-item>
					</el-row>
				</el-card>
				<el-card shadow="never" class="mt-24 mt-3">
					<div slot="header">
						<span>佣金设置</span>
					</div>
					<el-row>
						<el-form-item label="一级分销佣金">
							<el-input-number :min="0" v-model="form.commission.first_money"></el-input-number> %
						</el-form-item>
						<el-form-item label="二级分销佣金">
							<el-input-number :min="0" v-model="form.commission.second_money"></el-input-number> %
						</el-form-item>
						<el-form-item label="三级分销佣金">
							<el-input-number :min="0" v-model="form.commission.third_money"></el-input-number> %
						</el-form-item>
					</el-row>
				</el-card>
				<el-form-item class="mt-2">
					<el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['app', 'layout'],
		data() {
			return {
				loading: false,
				form: {
					// 是否开启分销功能
					is_open: '0', // 参数值：1开启 0关闭
					// 分销层级
					level: '3', // 参数值：1一级 2二级 3三级
					// 佣金结算天数
					settle_days: '7',
					// 佣金设置
					commission: {
						// 一级佣金
						first_money: 0,
						// 二级佣金
						second_money: 0,
						// 三级佣金
						third_money: 0,
					},
					// 提现
					withdraw: {
						// 提现方式
						pay_type: [10], // 参数值：10微信支付 20支付宝支付 30银行卡支付
						// 微信支付自动打款
						wechat_pay_auto: '0', // 微信支付自动打款：1开启 0关闭
						// 最低提现额度
						min_money: '10.00',
						// 手续费 ( 10% )
						service_charge: '10.00',
					}
				},
			}
		},
		created() {
			this.getSetting();
		},
		methods: {
			getSetting() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/member.dealer.setting/index',
					},
					method: 'get',
				}).then(res => {
					this.form = res.data.data
					this.layout.hideLoading()
				}).catch(err => {
					this.layout.hideLoading()
				})
			},

			// 提交
			submit() {
				this.loading = true;
				this.axios({
					token: true,
					params: {
						s: 'store/member.dealer.setting/index',
					},
					data: {
						dealer: this.form
					},
					method: 'post',
				}).then(res => {
					if (res.data.code == 1) {
						this.$message({
							message: res.data.msg,
							type: 'success'
						});
						// 跳转后台首页
						this.$router.push({
							name: 'member.dealer.setting/index'
						})
					} else {
						this.$message({
							message: res.data.msg,
							type: 'error'
						});
					}
					this.loading = false;
				}).catch(err => {
					this.loading = false;
				})
			},
		},
	}
</script>

<style>

</style>
